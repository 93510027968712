.navbar-mobile {
    display: none;

    @media only screen and (max-width: 425px) {
        display: flex;
    }
}

.navbar-mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 999;
    box-shadow: 0 0 8px rgba($color: #000000, $alpha: .16);
    transform: translateY(-100%);
    transition: 1s;

    &.show {
        transform: translateY(0%);
    }
}