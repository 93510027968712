.work {
    width: 100%;
    min-height: 100vh;
    background: #fafcff;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        // background: linear-gradient(to top, rgba(0, 0, 0, .9), rgba(0, 0, 0, .8));
        z-index: 1;
    }

    * {
        z-index: 2;
    }

    #work-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }

    #counter {
        width: 90%;
        height: 250px;
        background: linear-gradient(#61c7d7, #61c7d7) padding-box, linear-gradient(to top, #61c7d7, #61c7d7, #61c7d751) border-box;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border: 10px solid transparent;

        .counter-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: fit-content;

            * {
                color: white;
            }

            .DigitRoll {
                overflow: hidden;
                border: none;
            }

            .DigitRoll__Cell {
                font-size: 50px;
                font-weight: 700;
            }
        }
    }

    .title {
        color: #61c7d7;
    }

    .work-item {

        margin-bottom: 50px;

        @for $i from 1 through 14 {
            &.entrance-#{$i} {
                opacity: 0;
                transform: translateY(300%);
                animation: work-item-entrance 1s #{($i - 1) * .03s} forwards;
            }

            &.exit-#{$i} {
                opacity: 1;
                transform: translateY(0%);
                animation: work-item-exit 1s #{($i - 1) * .03s} forwards;
            }
        }

        @keyframes work-item-entrance {
            0% {
                transform: translateY(300%);
                opacity: 0;
            }

            100% {
                transform: translateY(0%);
                opacity: 1;
            }
        }

        @keyframes work-item-exit {
            0% {
                transform: translateY(0%);
                opacity: 1;
            }

            100% {
                transform: translateY(300%);
                opacity: 0;
            }
        }
    }

    @media only screen and (max-width: 1440px) {
        .title {
            font-size: 1.7rem;
        }
    }

    @media only screen and (max-width: 1024px) {
        padding: 50px;
        padding-top: 200px;
        // padding-top: calc(100vh - 175px);
    }

    @media only screen and (max-width: 768px) {

        #counter {
            .mantine-Text-root {
                text-align: center;
            }
        }

        .work-container {
            .mantine-Image-root {
                width: 200px !important;

                img {
                    width: 200px !important;
                }
            }
        }
    }

    @media only screen and (max-width: 425px) {
        padding: 20px;
        padding-top: 200px;

        .work-container {
            .mantine-Image-root {
                width: 150px !important;

                img {
                    width: 150px !important;
                }
            }
        }
    }
}

.work-item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0s;
    overflow: hidden;

    .screen {
        border-radius: 20px;
        background: #ffffff50;
        width: fit-content;
        backdrop-filter: blur(3px);
    }
}

#slide-controls {
    position: absolute;
    bottom: 80px;
    right: 80px;

    @media screen and (max-width: 768px) {
        bottom: 30px;
        right: 30px;
    }
}

#our-work-title {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
}

#pay1st {
    background: linear-gradient(126deg, rgba(255, 201, 143, .95) 0%, rgba(255, 159, 72, .95) 100%);

    &-screens {

        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        >* {
            position: absolute;
            top: 50%;
            left: 50%;
            // transform: translate(-50%, -50%);
            transition: 0s;
        }

        @media screen and (max-height: 481px) {
            #pay1st-device {
                transform-origin: bottom right;
            }
        }

    }


    // #pay1st-1 {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transition: 0s;
    //     transform: translate(-30%, -50%);

    //     img {
    //         max-width: 850px;
    //         width: 60vw;
    //     }
    // }

    // #pay1st-2 {
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transition: 0s;
    //     transform: translate(-100%, 100%);

    //     img {
    //         max-width: 670px;
    //         width: 50vw;
    //     }
    // }

    // #pay1st-device {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transition: 0s;
    //     transform: translate(-60%, -20%);
    //     transform-origin: bottom right;
    // }

    // #pay1st-screens{
    //     position: absolute;
    //     // top: 50%;
    //     // left: 50%;
    //     // transform: translate(-50%, -50%);
    // }
}

#ecmd {
    background: linear-gradient(90deg, rgba(168, 222, 254, .95) 0%, rgba(45, 123, 169, .95) 100%);

    &-screens {

        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        transform-origin: bottom center;

        >* {
            position: absolute;
            left: 50%;
            bottom: 0px;
            transition: 0s;
        }

        #ecmd-device {
            top: unset;
            bottom: -16px;
            transform-origin: bottom center;
        }

        @media screen and (max-height: 481px) {
            #pay1st-device {
                transform-origin: bottom left;
            }
        }
    }

    // #ecmd-screens {
    //     position: absolute;
    //     left: 50%;
    //     bottom: 0;
    //     transform: translate(-50%);
    //     transform-origin: bottom center;
    // }



    // #ecmd-1 {
    //     position: absolute;
    //     bottom: 20px;
    //     left: 50%;
    //     // top: 50%;
    //     transition: 0s;
    //     transform-origin: bottom;
    //     z-index: 2 !important;
    // }

    // #ecmd-2 {
    //     position: absolute;
    //     left: 50%;
    //     bottom: 20px;
    //     transition: 0s;
    //     transform: translate(-140%, -5%);
    //     transform-origin: left bottom;
    //     z-index: 2 !important;
    // }

    // #ecmd-device {
    //     position: absolute;
    //     bottom: -20px;
    //     left: calc(50% + 20px);
    //     transition: 0s;
    //     transform: translate(-50%);
    //     transform-origin: bottom;
    // }
}

#uhris {
    background: linear-gradient(90deg, rgba(255, 213, 208, .95) 0%, rgba(255, 210, 129, .95) 100%);

    &-screens {

        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        >* {
            position: absolute;
            top: 50%;
            left: 50%;
            // transform: translate(-50%, -50%);
            transition: 0s;
        }

    }

    // #uhris-screens {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     // transform-origin: bottom center;
    // }

    // #uhris-1 {
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transition: 0s;
    //     transform: translate(-20%, -20%);
    //     transform-origin: top right;
    // }

    // #uhris-2 {
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transition: 0s;
    //     transform: translate(-160%, -20%);
    //     transform-origin: top left;
    // }

    // #uhris-device {
    //     position: absolute;
    //     transition: 0s;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -26%);
    //     transform-origin: bottom center;
    // }
}

#icash {
    background: linear-gradient(90deg, rgba(254, 248, 199, .95) 0%, rgba(221, 175, 53, .95) 100%);

    &-screens {

        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        >* {
            position: absolute;
            top: 50%;
            left: 50%;
            // transform: translate(-50%, -50%);
            transition: 0s;
        }

    }

    // #icash-1 {
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transition: 0s;
    //     transform: translate(-35%, -3%);
    //     transform-origin: bottom right;
    // }

    // #icash-2 {
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transition: 0s;
    //     transform: translate(-35%, -125%);
    //     transform-origin: top right;
    // }

    // #icash-3 {
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transition: 0s;
    //     transform: translate(-270%, -40%);
    //     transform-origin: top left;
    // }

    // #icash-device {
    //     position: absolute;
    //     transition: 0s;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-60%, -30%);
    //     transform-origin: bottom center;

    //     img {
    //         height: 800px;
    //     }
    // }
}

#sfaa {
    background: linear-gradient(90deg, rgba(142, 187, 246, 1) 0%, rgba(38, 111, 204, 1) 100%);

    &-screens {

        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        >* {
            position: absolute;
            top: 50%;
            left: 50%;
            // transform: translate(-50%, -50%);
            transition: 0s;
        }

    }

    // #sfaa-screens {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     // transform-origin: bottom center;
    // }

    // #sfaa-1 {
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transition: 0s;
    //     transform: translate(-40%, -30%);
    //     transform-origin: top right;
    // }

    // #sfaa-2 {
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transition: 0s;
    //     transform: translate(-260%, -30%);
    //     transform-origin: top left;
    // }

    // #sfaa-device {
    //     position: absolute;
    //     transition: 0s;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-60%, -20%);
    //     transform-origin: bottom right;

    //     img {
    //         height: 800px;
    //     }
    // }
}



@media screen and (max-width: 1440px) {


    // #icash-screens,
    // #sfaa-screens {
    //     position: absolute;
    //     top: 50% !important;
    //     left: 50% !important;
    //     transform: translate(-50%, -50%) scale(.7) !important;
    // }

    // #ecmd-screens {
    //     position: absolute;
    //     top: 50% !important;
    //     left: 50% !important;
    //     transform: translate(-50%, -50%) scale(.9) !important;
    //     transform-origin: bottom center;
    // }
}

@media screen and (max-width: 1024px) {

    #our-work-title {
        top: 120px !important;
    }

    // #icash-screens,
    // #sfaa-screens {
    //     position: absolute;
    //     top: 50% !important;
    //     left: 50% !important;
    //     transform: translate(-50%, -40%) scale(.55) !important;
    // }

    // #ecmd-screens {
    //     position: absolute;
    //     top: 50% !important;
    //     left: 50% !important;
    //     transform: translate(-50%, -41.5%) scale(.55) !important;
    // }
}

@media screen and (max-width: 768px) {
    // #ecmd-screens {
    //     position: absolute;
    //     top: 50% !important;
    //     left: 50% !important;
    //     transform: translate(-50%, -44%) scale(.55) !important;

    //     #ecmd-1 {
    //         z-index: 2;
    //     }

    //     #ecmd-2 {
    //         z-index: 1;
    //     }
    // }
}