.contact {
    width: 100%;
    min-height: calc(100vh - 80px);
    background: #eff6ff;
    overflow: hidden;
    
    #form-container,
    #form-content {
        width: 100%;
        border-radius: 10px;
        background: white;
        height: 100%;
        position: relative;
        z-index: 1;
        box-shadow: 0 0 16px rgba($color: #000000, $alpha: .16);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #form-container {
        position: absolute;
        top: 0;
        transform-origin: bottom left;
        opacity: 0;
        transform: scale(0);

        &.entrance {
            animation: expandAndShow 1s forwards;
        }

        &.exit {
            animation: shrinkAndHide 1s forwards;
        }
    }

    #form-content {
        // position: absolute;
        top: 0;
        padding: 12%;
        background: transparent;
        box-shadow: none;

        &>* {

            @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                    transform: translateX(-100%);
                    opacity: 0;

                    &.entrance {
                        animation: form-content-entrance 1s #{(($i - 1) * .1s)} forwards;
                    }

                    &.exit {
                        transform: translateX(0%);
                        opacity: 1;
                        animation: form-content-exit 1s #{((6 - $i) * .05s)} forwards;
                    }
                }
            }
        }

        >* {
            &:not(:nth-child(1)):not(:nth-child(2)) {
                margin-top: 30px;
            }
        }

        @keyframes form-content-entrance {
            0% {
                transform: translateX(-100%);
                opacity: 0;
            }

            100% {
                transform: translateX(0%);
                opacity: 1;
            }
        }

        @keyframes form-content-exit {
            0% {
                transform: translateX(0%);
                opacity: 1;
            }

            100% {
                transform: translateX(-100%);
                opacity: 0;
            }

        }
    }

    #top-right-border {
        height: 60%;
        width: 60%;
        border-radius: 10px;
        background: #61c7d7;
        position: absolute;
        top: -10px;
        right: -20px;
        transform-origin: top right;
        opacity: 0;
        transform: scale(0);

        &.entrance {
            animation: expandAndShow 1s forwards;
        }

        &.exit {
            animation: shrinkAndHide 1s forwards;
        }
    }

    #bottom-left-border {
        height: 60%;
        width: 60%;
        border-radius: 10px;
        background: #61c7d7;
        position: absolute;
        bottom: -10px;
        left: -5px;
        transform-origin: bottom left;
        opacity: 0;
        transform: scale(0);

        &.entrance {
            animation: expandAndShow 1s forwards;
        }

        &.exit {
            animation: shrinkAndHide 1s forwards;
        }
    }

    .contact-us-details {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &>* {

            @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                    transform: translateX(100%);
                    opacity: 0;

                    &.entrance {
                        animation: details-entrance 1s #{(($i - 1) * .1s)} forwards;
                    }

                    &.exit {
                        transform: translateX(0%);
                        opacity: 1;
                        animation: details-exit 1s #{((6 - $i) * .05s)} forwards;
                    }
                }
            }
        }

        @keyframes details-entrance {
            0% {
                transform: translateX(100%);
                opacity: 0;
            }

            100% {
                transform: translateX(0%);
                opacity: 1;
            }
        }

        @keyframes details-exit {
            0% {
                transform: translateX(0%);
                opacity: 1;
            }

            100% {
                transform: translateX(100%);
                opacity: 0;
            }

        }
    }

    @keyframes expandAndShow {
        0% {
            transform: scale(0);
            opacity: 0;
        }

        100% {
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes shrinkAndHide {
        0% {
            transform: scale(1);
            opacity: 1;
        }

        100% {
            transform: scale(0);
            opacity: 0;
        }
    }

    @media only screen and (max-width: 1440px) {
        .title {
            font-size: 1.7rem;
        }
    }

    @media only screen and (max-width: 1024px) {
        padding: 100px;

        h3 {
            font-size: 1rem;
        }

        .mantine-Image-root {
            width: 50px !important;

            img {
                width: 50px !important;
            }
        }

        #form-content {
            h2 {
                font-size: 1.3rem;
            }
        }
    }

    @media only screen and (max-width: 768px) {

        .content-container {
            flex-direction: column-reverse;
            gap: 100px;
            height: fit-content;

            >.mantine-Grid-col {
                flex-basis: 100%;
                max-width: 100%;
            }
        }

        .contact-us-details {
            padding: 0;
        }
    }

    @media only screen and (max-width: 425px) {
        padding: 50px;
        padding-top: 130px;

        #form-content {
            .mantine-Group-root {
                justify-content: center;
            }
        }
    }

    @media only screen and (max-width:320px) {
        padding: 20px;
    }

}
  
  
  
  