.services {
    width: 100%;
    min-height: 100vh;
    background: #eff6ff;
    overflow: hidden;
    background-image: url(../../../public/img/tech-bg.png);
    background-position: right -150px;
    background-repeat: no-repeat;

    @for $i from 0 through 3 {
        .entrance-#{$i} {
            position: relative;
            transform: translateX(-150%);
            animation: services-entrance 1s #{$i * .1s} forwards;
        }
    }

    @for $i from 0 through 3 {
        .exit-#{$i} {
            position: relative;
            transform: translateX(0%);
            animation: services-exit 1s #{$i * .1s} forwards;
        }
    }

    @keyframes services-entrance {
        0% {
            transform: translateX(-150%);
        }

        100% {
            transform: translateX(0%);
        }
    }

    @keyframes services-exit {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(-150%);
        }
    }

    .top-right-border {
        position: absolute;
        width: 300px;
        height: 200px;
        background: #8fd7e5;
        top: -5px;
        right: -5px;
        z-index: 0;
        border-radius: 5px;
    }

    .bottom-left-border {
        position: absolute;
        width: 300px;
        height: 150px;
        background: #8fd7e5;
        bottom: -21%;
        left: 26px;
        z-index: 0;
        border-radius: 10px;
    }

    .service-card {
        position: relative;
        padding: 40px 30px;
        background: white;
        border-radius: 5px;
        margin: 5px 0px 130px 30px;
        min-height: 600px;
        z-index: 1;
        box-shadow: 0px 5px 16px rgba($color: #000000, $alpha: .05);

        .card-title {
            z-index: 2;
            position: relative;
        }

        .card-index {
            position: absolute;
            top: 0px;
            right: 20px;
            color: #f0f0f0;
            z-index: 0;
        }

        .card-content {
            padding: 0 15px;
            margin-top: 30px;

            * {
                line-height: 1.8;
            }
        }

        .card-img {
            position: absolute;
            right: 10px;
            bottom: -20%;
            filter: brightness(.5);
        }
    }

    .mantine-Carousel-slide {
        transform: scale(1);
        position: relative;
        top: 0px;
        transition: .6s;

        @for $i from 1 through 7 {
            &:nth-child(#{$i}) {
                z-index: $i;
            }
        }
    }

    .mantine-Carousel-slide[data-active=false] {
        transform: scale(.9);
        transform-origin: top center;
        // top: -40px;
        opacity: .5;
    }

    #services-progress-mobile,
    #services-controls-mobile {
        margin-right: 100px;
        display: none;
    }
    @media only screen and (max-width: 1440px){
        #services-title{
            font-size: 1.7rem;
        }
        #content-text{
            font-size: 2.5rem;
        }
    }

    @media only screen and (max-width: 1024px) {
        padding: 100px;
        padding-top: 160px;
        padding-right: 0;
    }

    @media only screen and (max-width: 768px) {
        padding: 50px;
        padding-top: 130px;

        .main-content {
            &>.mantine-Grid-col {
                max-width: 100%;
                flex-basis: 100%;

                &:nth-child(1) {
                    padding-bottom: 0;
                }
                &:nth-child(2) {
                    padding-top: 0;
                }
            }

        }

        
        .mantine-Title-root{
            font-size: 1.8rem;
        }
        
        #content-text{
            font-size: 2rem;
        }
        .service-card-container {
            transform: scale(.8);
            transform-origin: left;
        }

        #services-progress,
        #services-controls {
            display: none;
        }

        #services-progress-mobile,
        #services-controls-mobile {
            display: block;
        }
        #services-controls-mobile{
            margin: 0;
        }

        .mantine-Carousel-root{
            height: 700px;
        }

    }

    @media only screen and (max-width: 425px){
        padding: 50px;
        padding-top: 130px;
        
        .main-content{
            margin: 0;

            &>.mantine-Grid-col {
                max-width: 100%;
                flex-basis: 100%;
                padding: 0;
                &:nth-child(2) {
                    left: -10px;
                    padding-top: 0;
                }
            }
        }

        #services-progress-mobile, #services-controls-mobile{
            position: relative;
            left: 10px;
        }

        #services-progress-mobile{
            margin-right: 50px;
        }
    }
    
    @media only screen and (max-width: 375px){
        .service-card-container {
            transform: scale(.68);
        }



        .mantine-Carousel-root{
            height: 650px;
        }
    }
}