#bg-corp {
  position: fixed;
  z-index: -1;
}

#banner {
  width: 100%;
  padding: 150px 0;
  background: rgba(0, 0, 0, .8);


  .banner-entrance {
    transform: translateY(100%);
    opacity: 0;
    animation: bannerEntrance 1s forwards;

    @keyframes bannerEntrance {
      0% {
        transform: translateY(100%);
        opacity: 0;
      }

      100% {
        transform: translateY(0%);
        opacity: 1;
      }
    }
  }

  .banner-exit {
    transform: translateY(0%);
    opacity: 1;
    animation: bannerExit 1s forwards;

    @keyframes bannerExit {
      0% {
        transform: translateY(0%);
        opacity: 1;
      }

      100% {
        transform: translateY(100%);
        opacity: 0;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 50px;
  }

  @media only screen and (max-width: 768px){
    .mantine-Text-root{
      font-size: 2rem;
    }
  }

  @media only screen and (max-width: 425px){
    .mantine-Text-root{
      font-size: 1.2rem;
    }
  }
}