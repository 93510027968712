.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

    .logo {
        position: absolute;
        top: 50% !important;
        left: 150px !important;
        transform: translateY(-50%);
    }

    .navbar-item {
        opacity: 0;

        @for $i from 1 through 6 {
            &:nth-child(#{$i}) {
                animation: fadedown 1s #{($i * .2) + 1}s forwards;
            }
        }

        @keyframes fadedown {
            0% {
                opacity: 0;
                transform: translateY(-100%);
            }

            100% {
                opacity: 1;
                transform: translateY(0%);
            }
        }
    }

    @media only screen and (max-width: 1024px){
        .logo{
            left: 20px !important;
        }
    }

    @media only screen and (max-width: 768px){

        .logo{
            left: 50px !important;
            width: 50px !important;
            img{
                width: 50px !important;
            }
        }

        .navbar-item{
            margin-right: 20px;
        }
        .mantine-Text-root{
            font-size: .8rem;
        }
    }

    @media only screen and (max-width: 425px) {
        display: none;
    }
}