.team {
    width: 100%;
    min-height: calc(100vh + 125px);
    height: fit-content;
    background: #fafcff;
    overflow: hidden;
    padding-bottom: 150px !important;

    &::before {
        content: '';
        display: inline-block;
        background-image: url(../../../public/img/tech-bg.png);
        background-position: 100% center;
        background-repeat: no-repeat;
        transform: scaleX(-1);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    #content-image {
        width: 100%;
        max-width: 600px;
        border-radius: 10px;
        // background: gray;
        height: 100%;
        position: relative;
        z-index: 1;

        #main-image {
            transition: 1s;
            transition-delay: .2s;

            &.entrance {
                transform: translateX(-150%);
                opacity: 0;
                animation: main-image-entrance 1s .2s forwards;
            }

            &.exit {
                transform: translateX(0%);
                opacity: 1;
                animation: main-image-exit 1s forwards;

            }

            @keyframes main-image-entrance {
                0% {
                    transform: translateX(-150%);
                    opacity: 0;
                }

                100% {
                    transform: translateX(0%);
                    opacity: 1;
                }
            }

            @keyframes main-image-exit {
                0% {
                    transform: translateX(0%);
                    opacity: 1;
                }

                100% {
                    transform: translateX(-150%);
                    opacity: 0;
                }
            }
        }
    }

    #top-left-border {
        height: 200px;
        width: 300px;
        border-radius: 10px;
        background: #61c7d7;
        position: absolute;
        top: -10px;
        left: -10px;
        transition: 1s;
        transform-origin: top left;

        &.entrance {
            opacity: 1;
            scale: 1;
        }

        &.exit {
            opacity: 0;
            scale: 0;
        }
    }

    #bottom-right-border {
        height: 200px;
        width: 300px;
        border-radius: 10px;
        background: #61c7d7;
        position: absolute;
        bottom: -10px;
        right: -10px;
        transition: 1s;
        transform-origin: bottom right;

        &.entrance {
            opacity: 1;
            scale: 1;
        }

        &.exit {
            opacity: 0;
            scale: 0;
        }
    }

    #content-label-container {
        position: absolute;
        top: 50%;
        right: 50px;
    }

    .content-label {
        width: 230px;
        height: 230px;
        // background: white;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
        border-radius: 10px;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        transform-origin: bottom left;
        transition: 1s;        

        #content-label-bg {
            width: 100%;
            height: 100%;
            background-color: white;
            border-radius: 10px;
            transition: 1s;
            transform-origin: bottom left;

            &.entrance {
                opacity: 1;
                scale: 1;
            }

            &.exit {
                opacity: 0;
                scale: 0;
            }
        }


        #main-label {
            position: absolute;
            transition: 1s;

            &.entrance {
                opacity: 1;
                transform: translateX(0);
            }

            &.exit {
                opacity: 0;
                transform: translateY(300%);
            }
        }
    }

    #label-top-right-border {
        width: 150px;
        height: 80px;
        background: #61c7d7;
        border-radius: 10px;
        position: absolute;
        top: -84px;
        right: -48px;
        transform: translate(50%, -50%);
        z-index: 1;
        transition: 1s;
        transform-origin: top right;

        &.entrance {
            opacity: 1;
            scale: 1;
        }

        &.exit {
            opacity: 0;
            scale: 0;
        }
    }


    #team-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;


        &>* {
            @for $i from 1 through 4 {
                &:nth-child(#{$i}) {
                    &.entrance {
                        transform: translateX(100%);
                        opacity: 0;
                        animation: team-content-entrance 2s #{($i - 1)  * .1s} forwards;
                    }

                    &.exit {
                        transform: translateX(0%);
                        opacity: 1;
                        animation: team-content-exit 2s #{($i - 1) * .1s} forwards;
                    }
                }
            }
        }

        @keyframes team-content-entrance {
            0% {
                transform: translateX(100%);
                opacity: 0;
            }

            100% {
                transform: translateX(0%);
                opacity: 1;
            }
        }

        @keyframes team-content-exit {
            0% {
                transform: translateX(0%);
                opacity: 1;
            }

            100% {
                transform: translateX(100%);
                opacity: 0;
            }
        }
    }

    .description-mobile {
        display: none;
        opacity: 0;
        transform: translateY(100%);


        @for $i from 2 through 3 {
            &.entrance:nth-child(#{$i}) {
                animation: rise-up-team 1s #{($i - 1) * .2s} forwards;
            }
        }

        @keyframes rise-up-team {
            0% {
                opacity: 0;
                transform: translateY(100%);
            }

            100% {
                opacity: 1;
                transform: translateY(0%);
            }
        }
    }

    @media only screen and (max-width: 1440px) {
        #pre-title {
            font-size: 1.7rem;
        }

        #title {
            font-size: 2.5rem;
        }

        #team-content {
            padding-left: 80px;
        }
    }

    @media only screen and (max-width: 1024px) {
        padding: 100px;

        #pre-title {
            font-size: 1.3rem;
        }

        #title {
            font-size: 1.8rem;
        }

        #team-content {
            padding-right: 0;
        }

        .description {
            display: none;

            &-mobile {
                display: block;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding-top: 90px;
        padding-bottom: 200px !important;

        .mantine-Grid-root {
            // flex-direction: column;

            .mantine-Grid-col {
                flex-basis: 100%;
                max-width: 100%;

                &:nth-child(1) {
                    transform: scale(.8);
                }
            }
        }

        #team-content {
            padding: 0;
        }
    }

    @media only screen and (max-width: 425px) {
        padding: 20px;
        padding-top: 100px;

        .content-label {
            width: 150px;
            height: 80px;
            right: 35px;
        }

        #team-content {
            padding: 30px;
        }

        #main-label {
            font-size: 1.1rem;
        }

        #label-top-right-border {
            top: -10px;
            right: 25px;
        }

        .description-mobile {
            padding: 30px;
            margin-top: 0;
        }
    }
}