.home {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right bottom, #42494d, #172227);
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;


    #home-logo {
        width: 500px !important;
        height: 500px;
        opacity: 0;
        position: relative;
        animation: fadeup 2s 1s forwards;

        img {
            height: 500px !important;
        }
    }

    .cog-container {
        position: absolute;
        top: 0;
        right: 0;
    }

    .cog {
        position: absolute;
        transform-origin: center center;
    }

    #cog {
        &1 {
            right: -50px;
            top: 10px;

            &>svg {
                transform: rotate(26deg);
                animation: cog1anim 4s 1s forwards cubic-bezier(0.42, 0, 0.1, 1.02);

                @keyframes cog1anim {
                    0% {
                        transform: rotate(26deg);
                    }

                    100% {
                        transform: rotate(-334deg);
                    }
                }
            }
        }

        &2 {
            right: 400px;
            top: 50px;

            &>svg {
                transform: rotate(18deg);
                animation: cog2anim 4s 1s forwards cubic-bezier(0.42, 0, 0.1, 1.02);

                @keyframes cog2anim {
                    0% {
                        transform: rotate(18deg);
                    }

                    100% {
                        transform: rotate(378deg);
                    }
                }
            }
        }

        &3 {
            right: 335px;
            top: 298px;

            &>svg {
                transform: rotate(294deg);
                animation: cog3anim 4s 1s forwards cubic-bezier(0.42, 0, 0.1, 1.02);

                @keyframes cog3anim {
                    0% {
                        transform: rotate(294deg);
                    }

                    100% {
                        transform: rotate(-66deg);
                    }
                }
            }
        }
    }

    .title {
        span {
            color: #6accdb;
        }
    }

    .scroll-to-explore {
        position: absolute;
        opacity: 0;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);

        animation: fadeup2 2s 1s forwards;

        #chevron1 {
            animation: chevronanim 5s .3s infinite;
        }

        #chevron2 {
            animation: chevronanim 5s infinite;
        }

        @keyframes chevronanim {
            0% {
                opacity: 0;
                transform: translateY(-100%);
            }

            20% {
                opacity: 1;
                transform: translateY(0%);
            }

            80% {
                opacity: 1;
                transform: translateY(0%);
            }

            90% {
                opacity: 0;
                transform: translateY(100%);
            }

            100% {
                opacity: 0;
                transform: translateY(100%);
            }
        }
    }

    .title-container {
        opacity: 0;
        position: relative;
        animation: fadeup 2s 1.2s forwards;
    }

    @keyframes fadeup {
        0% {
            opacity: 0;
            top: 100%;
        }

        100% {
            opacity: 1;
            top: 0%;
        }
    }

    @keyframes fadeup2 {
        0% {
            opacity: 0;
            bottom: -100%;
        }

        100% {
            opacity: 1;
            bottom: 30px;
        }
    }

    @media only screen and (max-width: 1440px) {
        .cog-container {
            transform: scale(.7);
        }

        #home-logo {
            width: 350px !important;
            height: 350px;

            img {
                height: 350px !important;
            }
        }

        .title-container {
            .pre-title {
                font-size: 1.5rem;
            }

            .title {
                font-size: 2rem;
            }
        }

        .content-container {
            gap: 3rem;
        }
    }

    @media only screen and (max-width: 768px) {

        .cog-container{
            transform: scale(.5);
        }

        .content-container {
            flex-direction: column;
        }

        .title-container {
            width: 80%;
            .title {
                font-size: 2rem;
                text-align: center;
            }

            .pre-title {
                text-align: center;
            }
        }

        .scroll-to-explore{
            .mantine-Center-root{
                transform: scale(.7);
            }
        }
    }

    @media only screen and (max-width: 425px) {

        .cog-container{
            transform: scale(.4);
        }

        #home-logo{
            height: 300px;
            width: 300px !important;
            img{
                height: 300px !important;
            }
        }
    }

    @media only screen and (max-width: 375px) {
        #home-logo{
            height: 200px;
            width: 200px !important;
            img{
                height: 200px !important;
                width: 200px !important;
            }
        }

        .title-container{
            .pre-title{
                font-size: 1rem;
            }
            .title{
                font-size: 1.5rem;
            }
        }
    }
}